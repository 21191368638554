:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;
  --fc-button-text-color: #fff;
  --fc-button-bg-color: rgb(23, 31, 57);
  --fc-button-border-color: rgb(23, 31, 57);
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;
  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-list-event-hover-bg-color: #f5f5f5;
  --x-fc-current-day-active-month-view: rgb(23, 31, 57);
}

.fc {
  .fc-icon {

    /* stylelint-disable declaration-no-important */
    background: var(--fc-button-text-color) !important;

    /* stylelint-enable declaration-no-important */
  }

  .fc-icon-add {

    /* stylelint-disable declaration-no-important */
    background: #3b3b3b !important;

    /* stylelint-enable declaration-no-important */
  }

  .fc-day-today {
    background-color: var(--fc-today-bg-color);
  }

  .fc_event_title {
    color: black;
  }

  .fc-event-main {
    overflow: hidden;
  }

  .fc-daygrid-event {
    white-space: initial;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .fc .fc-dayGridMonth-button , .fc .fc-dayGridWeek-button {

    /* stylelint-disable declaration-no-important */
    display: block !important;

    /* stylelint-enable declaration-no-important */
  }

  .fc-toolbar-chunk {
    .fc-button-group {
      .fc-dayGridMonth-button {
        margin-top: 0.6em;
      }

      .fc-timeGridWeek-button {
        margin-top: 0.6em;
      }

      .fc-listMonth-button {
        margin-top: 0.6em;
      }
    }
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .fc-today-button {

    /* stylelint-disable declaration-no-important */
    margin-left: 0 !important;

    /* stylelint-enable declaration-no-important */
  }
}

.z-100 {
  z-index: 100;
}

.fc-filter-active {

  /* stylelint-disable declaration-no-important */
  --x-button-bg-color: #212529 !important;
  --x-button-border-color: #DEE2E6 !important;
  --x-button-color: #DEE2E6 !important;
  --x-button-box-shadow-color: rgba(222, 226, 230, 0.5) !important;

  /* stylelint-enable declaration-no-important */
}

.calendar-line-box {
  width: 100%;
  height: 100%;
  top: 0;
}

#calendar-filters {
  gap: 0.5em;

  &[aria-expanded="false"] {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
  }

  &[aria-expanded="true"] {
    height: max-content;
    overflow: hidden;
    transition: 0.2s;
  }

  label {
    margin-bottom: 0;
  }

  p {
    display: flex;
    flex-direction: column;
    width: 50%;

    ul {
      height: 2em;
      overflow: auto;
    }
  }
}

@media only screen and (max-width: 43rem) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: column-reverse;

    .fc-toolbar-chunk {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .fc-button-group {
        margin-bottom: 0.5em;

        .fc-today-button {

          /* stylelint-disable declaration-no-important */
          margin-left: 0 !important;

          /* stylelint-enable declaration-no-important */
        }
      }
    }
  }

  .fc-filter-button {

    /* stylelint-disable declaration-no-important */
    margin-left: 1em;

    /* stylelint-enable declaration-no-important */
  }

  .select2-container {

    /* stylelint-disable declaration-no-important */
    width: 21.4rem !important;

    /* stylelint-enable declaration-no-important */
  }

  #calendar-filters {
    flex-direction: column;

    p {
      width: 100%;
    }
  }
}
